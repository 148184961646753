export default {
    en: {
        title: "Forget Password",
        emailLabel: "Email",
        emailMessage: "Enter your email address",
        emailFormatMessage: "Please enter correct email address",
        send: "Send Request",
        failSend: "Request Error: ",
        successSend: "Request sent successfully, please go to your email inbox and look for temp login from support@pandance.org, please note your spam folder"
    },
    zh: {
        title: "忘记密码",
        emailLabel: "电子邮箱",
        emailMessage: "请填写电子邮箱",
        emailFormatMessage: "请输入正确的电子邮箱地址",
        send: "发送请求",
        failSend: "请求错误: ",
        successSend: "发送请求成功，请前往您的电子邮箱查看来自support@pandance.org临时登录邮件，注意您的垃圾箱"
    }
}